import React, { Component } from 'react'
import { Link } from 'gatsby'

import LHSBanner from './lhs-banner'
import Lead from './archive/lead'
import Tile from './archive/tile'
import List from './archive/list'

class SplitScreen extends Component {
  render() {
    let { dark, homepage, categories, rhs, top_articles } = this.props
    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <div className='split-screen'>
        <LHSBanner {...this.props} key={this.props.lhs} />
        <div className='split-screen__wrapper'>
          <div className={`split-screen__inner ${dark && 'archive--dark'} ${homepage && 'split-screen--homepage'}`}>
            { homepage && (
              <div className='split-screen__sidebar'>
                <nav role='navigation'>
                  <ul style={{ width: categories.length * 100 }}>
                    { categories.map((el, i) => <li key={i}><Link to={`/${el.node.slug}/`} {...props}>{ el.node.name }</Link></li>) }
                  </ul>
                </nav>
                <img src={require('../assets/images/small_logo_black.svg')} alt='TheFatherHood Logo' />
              </div>
            ) }
            <div className='split-screen__content'>
              <div className='split-screen__content__inner'>
              <List articles={top_articles} />
                { rhs && rhs.map((el, i) => {
                  if (i === 1) return <Lead {...el} key={i} /> 
                  return <Tile {...el} key={i} />
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SplitScreen
