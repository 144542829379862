import React, { Component } from 'react'

import SocialBar from './social-bar'

const social = {
  title: 'Join the hood',
  className: ''
}

class LHSBanner extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let banner = this.refs.background
    let docHeight = document.documentElement.offsetHeight
      
    // normalize scroll position as percentage
    let scrolled = (window.scrollY / ( docHeight - window.innerHeight ) / 2 ) + 1
    let y = -(window.scrollY / ( docHeight - window.innerHeight ) * 22 )
    let transformValue = 'scale('+scrolled+') translateY('+y+'%)'

    banner.style.WebkitTransform = transformValue
    banner.style.MozTransform = transformValue
    banner.style.OTransform = transformValue
    banner.style.transform = transformValue
  }

  render() {
    return (
      <div className='lhs-banner'>
        <div className='lhs-banner__background' style={{ backgroundImage: `url(${this.props.lhs})`}} ref='background' />
        <svg width="622px" height="515px" viewBox="0 0 622 515" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-98.000000, -346.000000)" fill="#FEFEFE">
							<g transform="translate(98.000000, 346.000000)">
								<g transform="translate(311.000000, 257.852732) rotate(-270.000000) translate(-311.000000, -257.852732) translate(54.000000, -53.147268)">
									<polygon points="0 -0.000443230404 0 78.603514 211.627743 78.603514 211.627743 112.868179 0 112.868179 0 193.488835 513.281492 193.488835 513.281492 112.868179 284.858271 112.868179 284.858271 78.603514 513.281492 78.603514 513.281492 -0.000443230404"></polygon>
									<polygon points="0 237.72973 0 387.548993 513.281492 387.548993 513.281492 307.599093 285.690067 307.599093 285.690067 245.792091 212.459539 245.792091 212.459539 307.599093 71.213829 307.599093 71.213829 237.72973"></polygon>
									<polygon points="0 431.790184 0 621.918298 71.213829 621.918298 71.213829 566.156958 513.281492 566.156958 513.281492 486.880768 71.213829 486.880768 71.213829 431.790184"></polygon>
								</g>
							</g>
						</g>
					</g>
        </svg> 
        <SocialBar {...social} />
      </div>
    )
  }
}

export default LHSBanner 
