import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Tag from '../tag'
import logo from '../../assets/images/small_logo.svg'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class Video extends Component {

  state = { show: false };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    let feature_image = this.props.acf && this.props.acf.featured_image && this.props.acf.featured_image.url

    let { type } = this.props.acf
    if (this.props.acf.sponsored) type = 'Sponsored'

    return (
      <div className='archive__video' style={{ backgroundImage: `url(${feature_image})` }}>
        <Fade bottom distance='40px'>
          <div className='archive__video__inner'>
            <div className='archive__video__text'>
              <Tag category={ type } />
              <h2>{ this.props.title ? this.props.title : this.props.post_title }</h2>
              <img src={logo} alt='the father hood logo' />
            </div>
            <div className='archive__video__player' style={{ backgroundImage: `url(${feature_image})` }} onClick={this.showModal}>
              <div className='archive__video__button'></div>
            </div>
          </div>
        </Fade>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="video-wrapper" dangerouslySetInnerHTML={{__html: this.state.show && this.props.acf.video }} />
        </Modal>
      </div>
    )
  }
}

export default Video
