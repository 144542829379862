import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Tag from '../tag'

class Bar extends Component {
  render() {
    let link = this.props.slug ? this.props.slug : this.props.post_name
    let url = `/article/${link}`
    let feature_image = this.props.acf && this.props.acf.featured_image.url
    let { category } = this.props
    if (this.props.acf.sponsored) category = 'Sponsored'

    return (
      <div className='archive__bar'>
        <Fade bottom distance='40px'>
          <Link to={ url }  className='archive__bar__inner'>
            <div className='archive__bar__image'>
              <picture style={{ backgroundImage: `url(${ feature_image })` }} />
              <Tag category={ category } />
            </div>
            <div className='archive__bar__text'>
              <h2>{ this.props.title ? this.props.title : this.props.post_title }</h2>
              <p dangerouslySetInnerHTML={{ __html: this.props.acf && this.props.acf.excerpt }} />
            </div>
          </Link>
        </Fade>
      </div>
    )
  }
}

export default Bar
