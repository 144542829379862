import React, { Component } from 'react'
import { Link } from 'gatsby'

class List extends Component {

  _item(el, i) {
    let link = el.node && el.node.slug ? el.node.slug : el.post_name
    return (
      <li key={i}>
        <Link to={`/article/${link}`}>
          { el.node && el.node.title ? el.node.title : el.post_title }
        </Link>
      </li>
    )
  }

  render() {
    return (
      <div className='archive__list'>
        <p>Top articles</p>
        <ul>
          { this.props.articles.map(this._item) }
        </ul>
      </div>
    )
  }
}

export default List
