import React, { Component } from 'react'
import { Link } from 'gatsby'

import Tag from '../tag'

class Lead extends Component {
  render() {
    let link = this.props.slug ? this.props.slug : this.props.post_name
    let url = `/article/${link}`
    let feature_image = this.props.acf && this.props.acf.featured_image.url
    return (
      <Link className='archive__lead' to={ url }>
        <div className='archive__lead__text'>
          <h2>{ this.props.title ? this.props.title : this.props.post_title }</h2>
          <p dangerouslySetInnerHTML={{ __html: this.props.acf && this.props.acf.excerpt }} />
        </div>
        <div className='archive__lead__image'>
          <Tag category={this.props.category} />
          <picture style={{ backgroundImage: `url(${ feature_image })` }} />
        </div>
      </Link>
    )
  }
}

export default Lead
